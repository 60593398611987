<template>
  <v-container>
    <RestrictedFeatureAlert :stores="'仙台空港'" />
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          予約情報 一覧
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは予約情報の一覧画面です。<br>
                  予約番号と入庫予定時刻で検索できます。
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>

      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="店舗名"
              rules="required"
            >
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
           <v-row>
            <v-col cols="12" lg="4" class="pt-0 mb-6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                outlined
                dense
                clearable
                hide-details
                label="予約番号検索"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="4" class="pt-0">
              <v-menu
                v-model="isDatePickerMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduledEntrantTime"
                    label="入庫予定日検索"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="handleClearScheduledEntrantTime"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="scheduledEntrantTime"
                  no-title
                  scrollable
                  @input="handleDatePickerInput"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="4" class="pt-0">
              <div class="d-flex align-center justify-end">
                <div class="text-caption pl-4">表示件数</div>
                <div style="width: 100px;" class="pl-2">
                  <v-select
                    outlined
                    dense
                    v-model="itemsPerPage"
                    :items="pageItems"
                    item-text="text"
                    item-value="value"
                    hide-details
                  ></v-select>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <template v-if="selectedRequestClientStore">
        <v-card-text class="pa-3 pt-0">
          <v-data-table
            :items="reservations"
            item-key="id"
            :search="search"
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
            no-data-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
            :custom-filter="reservationNumberFilter"
          >
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr
                v-for="item in items"
                :key="item.id"
                >
                  <td class="px-2 text-left">{{ item.reservation_number }}</td>
                  <td class="px-2 text-left">{{ formattedDateTime(item.scheduled_entrant_time) }}</td>
                  <td class="px-2 text-left">{{ formattedDateTime(item.scheduled_exit_time) }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!isLoading">
                <tr>
                  <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && reservations.length == 0" class="text-center my-10">
            該当する予約情報がありません
          </v-container>
          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>

        </v-card-text>

        <v-row v-if="reservations.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>

      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>

    </v-card>
  </v-container>
</template>
<script>
import moment from "moment"
import RestrictedFeatureAlert from '../../components/RestrictedFeatureAlert'

export default {
  name: 'Reservations',
  components: {
    RestrictedFeatureAlert
  },
  data: () => ({
    drawer: true,
    isLoading: false,
    isDatePickerMenuOpen: false,

    request_client_stores: [],
    selectedRequestClientStore: null,

    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    reservations: [],
    scheduledEntrantTime: moment().format('YYYY-MM-DD'),

    headers: [
      { text: '予約番号', value: 'reservation_number', sortable: false },
      { text: '入庫予定時刻', value: 'scheduled_entrant_time', sortable: false },
      { text: '出庫予定時刻', value: 'scheduled_exit_time', sortable: false }
    ],
    mobileHeaders: [
      { text: '予約番号', value: 'reservation_number', sortable: false },
      { text: '入庫予定時刻', value: 'scheduled_entrant_time', sortable: false },
      { text: '出庫予定時刻', value: 'scheduled_exit_time', sortable: false }
    ],
  }),
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getFilteredReservations()
      }
    },

  },
  computed: {
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.reservations.length }] }
    },
    formattedDateTime() {
       return (value) => {
         return moment(value).format('YYYY/MM/DD HH:mm:ss')
       }
    },

  },
  methods: {
    handleDatePickerInput() {
      this.isDatePickerMenuOpen = false;
      this.getFilteredReservations();
    },
    handleClearScheduledEntrantTime() {
      this.scheduledEntrantTime = null;
      this.getFilteredReservations();
    },
    getFilteredReservations() {
      this.page = 1
      this.itemsPerPage = 10
      this.getReservations()
    },
    async getReservations() {
      this.isLoading = true
      const time = this.scheduledEntrantTime ? moment(this.scheduledEntrantTime).format('YYYY-MM-DD') : ''
      const url = `${process.env.VUE_APP_BASE_URL}reservations?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}&scheduled_entrant_time=${time}`
      await this.axios.get(url)
        .then(response => {
          this.reservations = response.data.reservations
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores() {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({ path: "/login" })
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    reservationNumberFilter(_, search, item) {
      if (!search) return true
      return item.reservation_number.toString().includes(search)
    },
  },
  mounted() {
    this.getRequestClientStores();
  }
}
</script>
<style>
</style>
