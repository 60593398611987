<template>
  <v-alert v-if="isAdmin" dense text type="warning" :icon="false" class="mt-4">
    <p class="mb-1">
      こちらは以下の店舗のみ対応しています。
    </p>
    <small>
      {{ stores }}
    </small>
  </v-alert>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'RestrictedFeatureAlert',
  props: {
    stores: {
      type: String,
      default: ''
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdminUser
    }
  }
})
</script>
