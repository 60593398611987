<template>
  <v-container>
    <RestrictedFeatureAlert :stores="'仙台空港'" />
    <v-card tile>
      <v-toolbar flat dense>
        <v-toolbar-title>入出場照会</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは入出場履歴の照会画面です。<br>
                  入庫日を期間指定すると、対象期間に入庫した車両が表示されます。
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>
      <v-container fill-height>
        <v-row>
          <v-divider class="my-1"></v-divider>
        </v-row>

        <v-row>
          <v-col lg="4" cols="6" class="py-6">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="py-0">
            <v-menu
              v-model="showTargetStartDateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="期間指定(開始日)"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedTargetStartDateFormatted"
                    label="期間指定(開始日)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="selectedTargetStartDate"
                no-title
                locale="ja"
                @input="showTargetStartDateSelector = false"
                :max="selectedTargetEndDate || today"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-menu
              v-model="showTargetEndDateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="期間指定(終了日)"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedTargetEndDateFormatted"
                    label="期間指定(終了日)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="selectedTargetEndDate"
                no-title
                locale="ja"
                @input="showTargetEndDateSelector = false"
                :min="selectedTargetStartDate || today"
                :max="today"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-btn
              style="height: 38px;"
              outlined
              small
              color="primary"
              :disabled="csvDownloading || isLoading || allCsvDownloading"
              @click="getVehicleInformations"
            >検索</v-btn>
            <v-btn
              style="height: 38px;"
              class="ml-4"
              outlined
              small
              color="blue-grey"
              @click="clearDates"
            >日付を元に戻す</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="4" cols="6" class="py-4">
            <v-select outlined dense v-model="area" :items="parkingAreas" item-text="area_name" item-value="area_no" hide-details label="エリア"></v-select>
          </v-col>
          <v-col lg="4" cols="6" class="py-4">
            <v-select outlined dense v-model="filter" :items="filterItems" item-text="text" item-value="value" hide-details label="状態"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <div class="d-flex justify-space-between align-center py-1 px-2 ma-2">
                <div class="font-weight-bold text-subtitle-1">検索結果<span class="text-h6 pl-2">{{ itemCount }}<span class="pl-1 text-caption font-weight-bold">件</span></span></div>
                <div class="pa-2 d-flex justify-end">
                  <div class="pa-2">
                    <v-btn text :loading="csvDownloading" @click="downloadSearchResultCsv" :disabled="itemCount === 0 || csvDownloading || isLoading || allCsvDownloading">
                      <v-icon>mdi-download-outline</v-icon><div class="font-weight-bold">CSV</div>
                    </v-btn>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="pa-2">
                    <v-btn text :loading="allCsvDownloading" :disabled="itemCount === 0 || csvDownloading || isLoading || allCsvDownloading" @click="downloadCsv"><v-icon>mdi-download-outline</v-icon><div class="font-weight-bold">CSV(全期間)</div></v-btn>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="d-flex align-center justify-end">
                    <div class="text-caption pl-4">表示件数</div>
                    <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
                  </div>
                </div>
              </div>
              <v-container v-if="isLoading" class="text-center my-10">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </v-container>
              <v-container v-else>
                <v-row>
                  <v-divider class="mx-0 my-1"></v-divider>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="currentItems"
                      item-key="carNumber"
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      @page-count="pageCount = $event"
                      :fixed-header="true"
                      persistent
                      disable-filtering
                      hide-default-footer
                      class="elevation-1"
                      height="500px"
                      no-data-text="データがありません"
                    >
                    <template v-slot:item="{ item }">
                      <tr style="cursor: 'pointer';" @click.stop="!csvDownloading && !allCsvDownloading && getShowVehicleInformations(item)">
                        <td><div class="py-2 vertical-center">{{ item.areaName }}</div></td>
                        <td><div class="py-2 vertical-center">{{ allNumber(item) }}</div></td>
                        <td><div class="py-2 vertical-center">{{ item.entrantTime }}</div></td>
                        <td><div class="py-2 vertical-center">{{ item.exitTime }}</div></td>
                        <td><div class="py-2 vertical-center white--text"><v-chip label small :color="selectStatusColor(item.status)"><span class="white--text">{{ item.status }}</span></v-chip></div></td>
                      </tr>
                    </template>
                    <template v-slot:footer>
                      <div class="pa-2">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                      </div>
                    </template>
                  </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-if="selectedItem" v-model="vehicleShowDialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title>利用詳細</v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text>
          <v-container v-if="selectedItem && isShowLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-if="selectedItem && selectedVehicleInformation">
            <v-row>
              <v-col md="4" cols="6">
                <v-hover v-slot="{ hover }">
                  <v-img max-height="250" max-width="300" style="cursor: pointer;" class="pa-0 ma-0" :src="selectedItem.imagePath" @click="showImage()">
                  <v-overlay absolute :value="hover">
                    <v-icon :class="{ 'on-hover': hover}">mdi-magnify-plus-outline</v-icon>
                    </v-overlay>
                  </v-img>
                </v-hover>
              </v-col>
              <v-col md="8" cols="6">
                <v-container>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <div class="text-caption">車両番号</div>
                      <div><strong>{{ allNumber(selectedItem) }}</strong></div>
                      <div class="d-flex align-center">
                        <div class="px-1">
                          <div class="text-caption">属性</div>
                          <div v-if="checkVehicleCategory" class="white--text">
                            <v-chip v-if="selectedItem.white" small label color="primary">精算不要車両</v-chip>
                            <v-chip v-if="selectedItem.mont" small label color="primary">月極車両</v-chip>
                            <v-chip v-if="selectedItem.accessible" small label color="primary">障害者車両</v-chip>
                            <v-chip v-if="selectedItem.reservation" small label color="primary">予約車両</v-chip>
                          </div>
                          <div v-else><v-chip small label>一般車両</v-chip></div>
                        </div>
                        <div class="px-1">
                          <div class="text-caption">利用状況</div>
                          <v-chip label small :color="selectStatusColor(selectedItem.status)"><span class="white--text">{{ selectedItem.status }}</span></v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.totalUsageTime }}<span class="text-caption">分</span></div>
                  <div class="text-caption">利用時間</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.barcodeCount }}<span class="text-caption">件</span></div>
                  <div class="text-caption">サービス券利用</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payCash }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（現金）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payCredit }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（クレジット）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payQr }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（QR決済）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payIcCard }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（電子マネー）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">
                    {{ selectedVehicleInformation.payArrears }}<span class="text-caption">円</span>
                    <v-chip v-if="selectedVehicleInformation.payFinishedFlg === 1" x-small label class="mx-1 pa-2">支払済</v-chip>
                  </div>
                  <div class="text-caption">未払い金額</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="font-weight-bold">■ 利用履歴</div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="selectedVehicleInformation.inoutRecords"
                  item-key="inoutNo"
                  :items-per-page="selectedVehicleInformation.inoutRecords.length"
                  dense
                  :headers="inoutHeaders"
                  mobile-breeakpoint="0"
                  no-data-text=""
                  hide-default-footer
                  fixed_header
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.inoutNo">
                        <td><div class="vertical-center">{{ item.areaName }}</div></td>
                        <td><div class="vertical-center">{{ item.entrantTime }}</div></td>
                        <td><div class="vertical-center">{{ item.exitTime }}</div></td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" :disabled="selectedItem && isShowLoading" @click.stop="close">閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && selectedVehicleInformation" v-model="showImageDialog" max-width="800px">
      <v-card>
        <v-img class="pa-0 ma-0" :src="selectedItem.imagePath"></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import RestrictedFeatureAlert from '../../components/RestrictedFeatureAlert'

export default Vue.extend({
  name: 'sendaiAirportVehicleInfos',
  components: { RestrictedFeatureAlert },
  data () {
    return {
      selectedRequestClientStore: null,
      request_client_stores: [],
      showTargetStartDateSelector: false,
      selectedTargetStartDate: '',
      selectedTargetStartDateFormatted: '',
      showTargetEndDateSelector: false,
      selectedTargetEndDate: '',
      selectedTargetEndDateFormatted: '',
      today: moment().format('YY/MM/DD'),
      selectedArea: { area_id: 0, area_name: 'すべて' },
      headers: [
        { text: '駐車エリア', value: 'areaName' },
        { text: '車両情報', value: 'carNumber' },
        { text: '入庫日時', value: 'entrantTime' },
        { text: '出庫日時', value: 'exitTime' },
        { text: '状態', value: 'status' }
      ],
      inoutHeaders: [
        { text: '駐車エリア', value: 'areaName' },
        { text: '入庫日時', value: 'entrantTime' },
        { text: '出庫日時', value: 'exitTime' }
      ],
      filter: 0,
      area: 0,
      filterItems: [ { text: '全て', value: 0 }, { text: '入庫中', value: 1 }, { text: '出庫済み', value: 2 }, { text: '移動', value: 3 }, { text: '未精算', value: 4 }],
      vehicle_informations: [],
      selectedVehicleInformation: null,
      selectedItem: null,
      vehicleShowDialog: false,
      isLoading: false,
      isShowLoading: false,
      itemsPerPage: 100,
      page: 1,
      pageCount: 0,
      csvDownloading: false,
      allCsvDownloading: false,
      parkingAreas: [{area_no: 0, area_name: '全て'}],
      showImageDialog: false,
      datesNotValid: false,
      csvHeaders: [
        '駐車エリア',
        '車両番号',
        '入庫日時',
        '出庫日時',
        '状態',
        '支払金額',
        'サービス券使用枚数',
        '支払金額(現金)',
        '支払金額(クレジット)',
        '支払金額(電子マネー)',
        '支払金額(QR決済)',
        '未精算金額',
        '未精算支払済み',
        '利用者属性(障害者車両)',
        '利用者属性(予約車両)',
        '利用者属性(精算不要車両)',
        '利用者属性(月極車両)'
      ]
    }
  },
  computed: {
    beforeOpenStores () {
      return this.$store.getters['store/beforeOpenStores'](new Date().toISOString())
    },
    timelineStyle: {
      get () {
        const height = this.$vuetify.breakpoint.height
        return `height: ${height > 750 ? (height - 100) / 2 : height - 300}px; overflow-y: scroll;`
      }
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('yyyy-MM-DD') : null
    },
    allNumber () {
      return (value) => {
        return value.place + value.classNumber + value.kana + value.carNumber
      }
    },
    selectStatusColor () {
      return (value) => {
        if (value === "出庫済み") {
          return "success"
        } else if (value === "未精算") {
          return "error"
        } else if (value === "入庫中") {
          return "amber"
        }
      }
    },
    currentItems () {
      let filterItems = []
      if (this.filter === 0) {
        filterItems =  this.vehicle_informations
      } else if (this.filter === 1) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '入庫中' )
      } else if (this.filter === 2) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '出庫済み' )
      } else if (this.filter === 3) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '移動' )
      } else {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '未精算' )
      }
      if (this.area === 0) {
        return filterItems
      } else if (this.area === 1) {
        return filterItems.filter((item) => item.areaNo === 1)
      } else if (this.area === 2) {
          return filterItems.filter((item) => item.areaNo === 2)
      } else if (this.area === 3) {
          return filterItems.filter((item) => item.areaNo === 3)
      } else if (this.area === 4) {
        return filterItems.filter((item) => item.areaNo === 4)
      } else {
        return filterItems.filter((item) => item.areaNo === 5)
      }
    },
    itemCount () {
      return this.vehicle_informations ? this.currentItems.length : 0
    },
    checkVehicleCategory () {
      return this.selectedItem && (this.selectedItem.white || this.selectedItem.mont || this.selectedItem.accessible || this.selectedItem.reservation)
    },
    csvName () {
      return '入出場履歴_' + this.selectedTargetStartDateFormatted + '.csv'
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.vehicle_informations.length }] }
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getFilteredVehicleInformations()
      }
    },
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    }
  },
  methods: {
    getFilteredVehicleInformations () {
      this.page = 1
      this.filter = 0
      this.area = 0
      this.getVehicleInformations()
    },
    async getVehicleInformations () {
      this.vehicle_informations = []
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isSendaiAdmin) {
        this.$router.push({path: "/"})
        return
      }
      this.isLoading = true
      const params = `store_id=${this.selectedRequestClientStore.id}&start_date=${this.selectedTargetStartDateFormatted}&end_date=${this.selectedTargetEndDateFormatted}`
      const url = process.env.VUE_APP_BASE_URL + "vehicle_informations?" + params
      try {
        const response = await this.axios.get(url)
        this.vehicle_informations = response.data.vehicle_informations
        this.parkingAreas = [...this.parkingAreas, ...response.data.parking_areas]
      } catch (err) {
        console.log(err)
        if (this.$route.path.match('/login')) {
          return
        } else if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `入出庫データの取得に失敗しました`,
            type: "is-danger",
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async getShowVehicleInformations (item) {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isSendaiAdmin) {
        this.$router.push({path: "/"})
        return
      }
      this.selectedVehicleInformation
      this.selectedItem = item
      const params = `?store_id=${this.selectedRequestClientStore.id}&entrant_no=${item.sourceEntrantNo}&parking_id=${item.sourceParkingId}&entrant_dte=${item.sourceEntrantDte}`
      const url = process.env.VUE_APP_BASE_URL + "vehicle_informations/" + item.inoutNo + params
      this.vehicleShowDialog = true
      this.isShowLoading = true
      try {
        const response = await this.axios.get(url)
        this.selectedVehicleInformation = response.data
      } catch (err) {
        if (this.$route.path.match('/login')) {
          return
        } else if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `入出庫データの取得に失敗しました`,
            type: "is-danger",
          })
        }
      } finally {
        this.isShowLoading = false
      }
    },
    close () {
      this.selectedVehicleInformation = null
      this.selectedItem = null
      this.vehicleShowDialog = false
    },
    async downloadSearchResultCsv () {
      if (confirm("CSVをダウンロードしますか？")) {
        this.csvDownloading = true
        try {
          const csvContent = this.generateCSVContent(this.currentItems)
          const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
          const blob = new Blob([bom, csvContent], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          const currentDateTime = moment().format('YYYYMMDDHHmmss')
          link.download = `入出庫照会_${currentDateTime}.csv`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } catch (error) {
          this.$buefy.toast.open({
            message: `CSVのダウンロードに失敗しました`,
            type: "is-danger",
          });
        } finally {
          this.csvDownloading = false;
        }
      }
    },
    generateCSVContent (data) {
      const rows = data.map(item => [
        item.areaName,
        item.number,
        item.entrantTime,
        item.exitTime || '',
        item.status,
        item.payTotal || 0,
        item.barcodeCount || 0,
        item.payCash || 0,
        item.payCredit || 0,
        item.payIcCard || 0,
        item.payQr || 0,
        item.payArrears || 0,
        item.payFinishedFlg ? '支払済み' : '', 
        item.accessible ? '障害者車両' : '',
        item.reservation ? '予約車両' : '',
        item.white ? '精算不要車両' : '',
        item.mont ? '月極車両' : ''
      ])
      const csvContent = [this.csvHeaders, ...rows].map(e => e.join(",")).join("\n")
      return csvContent
    },
    generateAllRecordsCSVContent (data) {
      const rows = data.map(item => [
        item.areaName,
        item.number,
        item.entrantTime,
        item.exitTime || '',
        item.status,
        item.payTotal || 0,
        item.barcodeCount || 0,
        item.payCash || 0,
        item.payCredit || 0,
        item.payIcCard || 0,
        item.payQr || 0,
        item.payArrears || 0,
        item.payFinishedFlg ? '支払済み' : '',
        item.accessibleInfo,
        item.reservationInfo,
        item.whiteInfo,
        item.montInfo
      ])
      const csvContent = [this.csvHeaders, ...rows].map(e => e.join(",")).join("\n")
      return csvContent
    },
    async downloadCsv () {
      this.allCsvDownloading = true
      const url = process.env.VUE_APP_BASE_URL + "csv_exports/export_vehicle_informations?store_id=" + this.selectedRequestClientStore.id
      try {
        const response = await this.axios.get(url, { resposeType: 'blob' })
        const all_records = response.data
        const csvContent = this.generateAllRecordsCSVContent(all_records)
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
        const blob = new Blob([bom, csvContent], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const currentDateTime = moment().format('YYYYMMDDHHmmss')
        link.download = `入出場照会(一括)_${currentDateTime}.csv`
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (error) {
        this.$buefy.toast.open({
          message: `csv出力に失敗しました。`,
          type: "is-danger",
        })
      } finally {
        this.allCsvDownloading = false
      }
    },
    showImage () {
      this.showImageDialog = true
    },
    clearDates () {
      this.selectedTargetStartDateFormatted = this.today
      this.selectedTargetEndDateFormatted = this.today
    }
  },
  mounted () {
    this.selectedTargetStartDateFormatted = this.selectedTargetStartDateFormatted || this.today
    this.selectedTargetEndDateFormatted = this.selectedTargetEndDateFormatted || this.today
    this.getRequestClientStores()
  }
})
</script>
<style scoped>
.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
}
.v-img .on-hover { transition: opacity .4s ease-in-out; cursor: pointer; }
.on-hover { opacity: 1; }
</style>
