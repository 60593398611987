<template>
  <v-container>
    <RestrictedFeatureAlert :stores="'仙台空港'" />
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          売上照会
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは売上照会の画面です。<br>
                  「支払日」は日付を選択してから検索ボタンをクリックして絞り込み表示ができます。<br>
                  「エリア」「決済種別」「ブランド」はセレクトボックスから1つ選択して絞り込みができます。<br>
                  「絞り込みをクリア」ボタンをクリックして、すべての絞り込みをクリアできます。<br>
                  「CSV」ボタンをクリックして、現在表示している分をCSVで出力できます。<br>
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>

      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="6" class="pb-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="店舗名"
              rules="required"
            >
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6" class="pb-0" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <div class="d-flex justify-end">
              <v-menu
                v-model="selectedDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedDate"
                    label="支払日"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                    class="rounded-r-0"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedDate"
                  no-title
                  locale="ja"
                  @input="changeSelectedDate"
                ></v-date-picker>
              </v-menu>
              <v-btn
                style="height: 40px;"
                outlined
                color="blue-grey"
                class="rounded-l-0"
                @click="getSalesInquiries"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" class="pb-2" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <v-select
              label="エリア"
              v-model="selectedArea"
              :items="areaOptions"
              item-text="areaName"
              item-value="areaName"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="pb-2" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <v-select
              label="決済種別"
              v-model="selectedPaymentType"
              :items="paymentTypes"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" class="pb-2" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <v-select
              label="ブランド"
              v-model="selectedBrand"
              :items="brandOptions"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="pb-2" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <div class="d-flex justify-end">
              <v-btn
                outlined
                color="primary"
                @click="clearFilters"
              >
                <span>絞り込みをクリア</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col cols="12" md="6" class="pb-0">
            <span>検索結果: {{ filteredSalesInquiries.length }}件</span>
          </v-col>
          <v-col cols="12" md="6" justify="right">
            <div class="d-flex align-center justify-end">
              <div class="pa-1">
                  <v-btn
                  text
                  :disabled="csvDownloading || isLoading || salesInquiries.length === 0"
                  @click="downloadFilteredCSV"
                >
                  <v-icon>mdi-download-outline</v-icon>
                  <div class="font-weight-bold">CSV</div>
                </v-btn>
              </div>
              <v-divider vertical class="mx-1"></v-divider>
              <div class="d-flex align-center justify-end pa-1">
                <div class="text-caption">表示件数</div>
                <div style="width: 100px;" class="pl-2">
                  <v-select
                    outlined
                    dense
                    v-model="itemsPerPage"
                    :items="pageItems"
                    item-text="text"
                    item-value="value"
                    hide-details
                  ></v-select>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
        <v-card-text class="pa-3 pt-0">
          <v-data-table
            :items="filteredSalesInquiries"
            item-key="id"
            :search="search"
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
            no-result-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr
                v-for="item in items"
                :key="item.id"
                style="cursor: 'pointer';"
                @click.stop="showDialog(item)"
                >
                  <td class="px-2 text-left">{{ item.areaName }}</td> <!-- 「エリア」-->
                  <td class="px-2 text-left">{{ formatDateTime(item.checkoutDte) }}</td> <!-- 「支払日時」-->
                  <td class="px-2 text-left">{{ carInfo(item) }}</td> <!-- 「車両番号」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.vehicleType }}</td> <!-- 「属性」-->
                  <td class="px-2 text-left">{{ item.payTotal }}</td> <!-- 「支払金額」-->
                  <td class="px-2 text-left">{{ item.paymentType }}</td> <!-- 「決済種別」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.unpaidPayTotal }}</td> <!-- 「未払いの精算金額」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.payCash }}</td> <!-- 「支払金額(現金)」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.payIcCard }}</td> <!-- 「支払金額(電子マネー)」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.payCredit }}</td> <!-- 「支払い金額(クレジット)」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ item.payQr }}</td> <!-- 「支払金額(QR決済)」-->
                  <td class="px-2 text-left hidden-md-and-down">{{ formatBrandName(item) }}</td> <!-- 「ブランド」-->
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && salesInquiries.length == 0" class="text-center my-10">
            該当する売上照会情報がありません
          </v-container>
          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>

        </v-card-text>

        <v-row v-if="salesInquiries.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>

      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>

    <!-- 詳細画面 -->
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>売上照会 詳細</v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
        ref="modal_content"
        class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="エリア"
              :value="shownInquiry.areaName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="支払日時"
                :value="formatDateTime(shownInquiry.checkoutDte)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="車両番号"
              :value="carInfo(shownInquiry)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="属性"
              :value="shownInquiry.vehicleType"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="支払金額"
              :value="shownInquiry.payTotal"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="決済種別"
              :value="shownInquiry.paymentType"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="未払いの精算金額"
              :value="shownInquiry.unpaidPayTotal"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="支払金額(現金)"
              :value="shownInquiry.payCash"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="支払金額(電子マネー)"
              :value="shownInquiry.payIcCard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="支払い金額(クレジット)"
              :value="shownInquiry.payCredit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="支払金額(QR決済)"
              :value="shownInquiry.payQr"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
              dense
              readonly
              label="ブランド"
              :value="formatBrandName(shownInquiry)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            color="blue-grey"
            @click="close"
          >閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import moment from "moment"
import RestrictedFeatureAlert from '../../components/RestrictedFeatureAlert'

export default {
  name: 'SalesInquiries',
  components: {
    RestrictedFeatureAlert
  },
  data: () => ({
    drawer: true,
    isLoading: false,

    request_client_stores: [],
    selectedRequestClientStore: null,

    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    selectedDateMenu: null, // カレンダーが開くとtrue、閉じるとfalseになる

    selectedArea: '全エリア・全フロア共通',
    selectedBrand: '全て',
    selectedPaymentType: '全て',

    salesInquiries: [],
    filteredSalesInquiries: [],

    errors: [],

    selectedDate: moment().format('YYYY-MM-DD'),
    startDateTime: moment().format('YYYY-MM-DD 00:00:00'),
    endDateTime: moment().format('YYYY-MM-DD 23:59:59'),

    csvDownloading: false,

    dialog: false,
    shownInquiry: '',

    headers: [
      { text: 'エリア', value: 'areaName', sortable: false },
      { text: '支払日時', value: 'checkoutDte', sortable: true },
      { text: '車両番号', value: 'carNumber', sortable: false },
      { text: '属性', value: 'vehicleType', sortable: false },
      { text: '支払金額', value: 'payTotal', sortable: false },
      { text: '決済種別', value: 'paymentType', sortable: true },
      { text: '未払いの精算金額', value: 'unpaidPayTotal', sortable: false },
      { text: '支払金額(現金)', value: 'payCash', sortable: false },
      { text: '支払金額(電子マネー)', value: 'payIcCard', sortable: false },
      { text: '支払い金額(クレジット)', value: 'payCredit', sortable: false },
      { text: '支払金額(QR決済)', value: 'payQr', sortable: false },
      { text: 'ブランド', value: 'brand', sortable: false },
    ],
    mobileHeaders: [
      { text: 'エリア', value: 'areaName', sortable: false },
      { text: '支払日時', value: 'checkoutDte', sortable: true },
      { text: '車両番号', value: 'carNumber', sortable: false },
      { text: '支払金額', value: 'payTotal', sortable: false },
      { text: '決済種別', value: 'paymentType', sortable: true },
    ],
  }),
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.setInitialPage()
      }
    },
    selectedArea() {
      this.filterSalesInquiries()
    },
    selectedBrand() {
      this.filterSalesInquiries()
    },
    selectedPaymentType() {
      this.filterSalesInquiries()
    }
  },
  computed: {
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.filteredSalesInquiries.length }] }
    },
    formatDateTime() {
       return (value) => {
         return moment(value).format('YYYY/MM/DD HH:mm:ss')
       }
    },
    uniqueAreas() {
      return [...new Set(this.salesInquiries.map(item => ({
        areaName: item.areaName
      })))];
    },
    areaOptions() {
      return [{ areaName: '全エリア・全フロア共通' }, ...this.uniqueAreas]
    },
    brandOptions() {
      // Setに初期値'全て'を設定
      const brands = new Set(['全て']);
      // salesInquiriesからブランド名を抽出してSetに追加(重複を除外)
      this.salesInquiries.forEach(item => {
        const paymentMethods = []
        // ICカード支払いが'CreditCard'の場合は、VISAやMASTERなどのブランド名を配列に追加
        if (item.paymentWay === 'CreditCard' && item.applicationLabel) {
          paymentMethods.push(item.applicationLabel)
        // それ以外は、Suica、Pasmo、iD、nanacoなどがあれば配列に追加
        } else if (item.paymentWay) {
          paymentMethods.push(item.paymentWay)
        }
        // QR決済のPayPay、楽天ペイ、auペイなどがあれば配列に追加
        if (item.paymentCompany) paymentMethods.push(item.paymentCompany)
        // Setに追加、もし複数の組み合わせがある場合は"+"で連結して追加
        if (paymentMethods.length > 0) brands.add(paymentMethods.join('+'))
      })
      // Setを配列に変換してからmapでセレクトボックス表示部分のオブジェクトを生成
      return Array.from(brands).map(brand => ({ text: brand, value: brand }));
    },
    paymentTypes() {
      // Setに初期値'全て'を設定
      const paymentTypesSet = new Set(['全て'])
      // salesInquiriesから決済種別を抽出してSetに追加(重複を除外)
      this.salesInquiries.forEach(item => {
        if (item.paymentType) {
          paymentTypesSet.add(item.paymentType)
        }
      })
      // Setを配列に変換してからmapでセレクトボックス表示部分のオブジェクトを生成
      return Array.from(paymentTypesSet).map(type => ({ text: type, value: type }))
    },
    carInfo() {
      return (value) => {
        return `${value.place} ${value.classNumber} ${value.kana} ${value.carNumber}`
      }
    },
  },
  methods: {
    setInitialPage() {
      this.page = 1
      this.itemsPerPage = 10
      this.getSalesInquiries()
    },
    async getSalesInquiries() {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}sales_inquiries?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}&start_datetime=${this.startDateTime}&end_datetime=${this.endDateTime}`
      await this.axios.get(url)
        .then(response => {
          this.salesInquiries = response.data.sales_inquiries
          this.filterSalesInquiries()
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores() {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({ path: "/login" })
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },

    async downloadFilteredCSV() {
      if (confirm("CSVをダウンロードしますか?")) {
        this.csvDownloading = true;
        try {
          const csvContent = this.generateCSVContent(this.filteredSalesInquiries)
          const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
          const blob = new Blob([bom, csvContent], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          const currentDateTime = moment().format('YYYYMMDDHHmmss')
          link.download = `売上照会一覧_${currentDateTime}.csv`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } catch (error) {
          this.$buefy.toast.open({
            message: `CSVのダウンロードに失敗しました`,
            type: "is-danger",
          });
        } finally {
          this.csvDownloading = false;
        }
      }
    },
    generateCSVContent(data) {
      const headers = [
        'エリア',
        '支払日時',
        '車両番号',
        '属性',
        '支払金額',
        '決済種別',
        '未払いの精算金額',
        '支払金額(現金)',
        '支払金額(電子マネー)',
        '支払い金額(クレジット)',
        '支払金額(QR決済)',
        'ブランド'
      ];
      const rows = data.map(item => [
        item.areaName,
        this.formatDateTime(item.checkoutDte),
        `${item.place} ${item.classNumber} ${item.kana} ${item.carNumber}`,
        item.vehicleType,
        item.payTotal,
        item.paymentType,
        item.unpaidPayTotal,
        item.payCash,
        item.payIcCard,
        item.payCredit,
        item.payQr,
        this.formatBrandName(item)
      ]);
      const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n")
      return csvContent
    },

    filterSalesInquiries() {
      this.filteredSalesInquiries = this.salesInquiries.filter(inquiry => {
        const matchesArea = (this.selectedArea === '全エリア・全フロア共通') ||
          (this.selectedArea === inquiry.areaName)
        const matchesBrand = (this.selectedBrand === '全て') ||
          (this.selectedBrand === this.formatBrandName(inquiry))
        const matchesPaymentType = (this.selectedPaymentType === '全て') ||
          (this.selectedPaymentType === inquiry.paymentType)
        return matchesArea && matchesBrand && matchesPaymentType
      })
    },
    clearSelectedDate() {
      this.selectedDate = null
      this.startDateTime = null
      this.endDateTime = null
    },
    changeSelectedDate(date) {
      this.selectedDateMenu = false
      if (date) {
        this.startDateTime = moment(date).format('YYYY-MM-DD 00:00:00')
        this.endDateTime = moment(date).format('YYYY-MM-DD 23:59:59')
      } else {
        this.startDateTime = null
        this.endDateTime = null
      }
    },
    clearFilters() {
      // 各フィルターを初期化
      this.selectedArea = '全エリア・全フロア共通'
      this.selectedBrand = '全て'
      this.selectedPaymentType = '全て'
      this.filterSalesInquiries()
    },
    formatBrandName(item) {
      const paymentMethods = []
      if (item.paymentWay === 'CreditCard' && item.applicationLabel) {
        paymentMethods.push(item.applicationLabel)
      } else if (item.paymentWay) {
        paymentMethods.push(item.paymentWay)
      }
      if (item.paymentCompany) paymentMethods.push(item.paymentCompany)
      return paymentMethods.join('+')
    },
    showDialog(item){
      this.shownInquiry = item
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    validateUserAccess() {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isSendaiUser) {
        this.$router.push({ path: '/' });
      }
    },
  },
  mounted() {
    this.validateUserAccess()
    this.getRequestClientStores()
  }
}
</script>
<style>
.custom-subheader {
  color: black;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
}
</style>
