<template>
  <v-container>
    <RestrictedFeatureAlert :stores="'イオンモール幕張新都心、イオンモール与野、レイクタウン'" />
    <v-card tile>
      <v-toolbar flat dense>
        <v-toolbar-title>入出場照会</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-alert dense text type="info" :icon="false" class="mb-2">
              <small>
                こちらは入出場履歴の照会画面です。<br>
                入庫日を選択すると、対象の日付に入庫した車両が表示されます。<br>
                アプリ精算の精算履歴は表示されません。
              </small>
            </v-alert>
            </v-col>
        </v-row>
      </v-card-text>
      <v-container fill-height>
        <v-row>
          <v-divider class="my-1"></v-divider>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <div><small class="font-weight-bold">検索条件</small></div>
              <div>
                <v-btn icon small class="font-weight-black" :color="drawer ? 'grey' : 'info'" @click.stop="drawer = !drawer">
                  <v-icon>{{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <template v-if="drawer">
          <v-row>
            <v-col lg="4" cols="12">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="店舗名"
                  rules="required"
                >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :error-messages="errors"
                  :disabled="!$store.getters.isAdminUser || isLoading"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <small>入庫日を選択してください。<span class="red--text">(始点は必須)</span></small>
              <v-divider class="mt-0 mb-3"></v-divider>
            </v-col>
            <v-col lg="4" cols="12">
              <v-menu
                v-model="showTargetStartDateSelector"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="日付"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="selectedTargetStartDateFormatted"
                      label="入庫日(始点)"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="selectedTargetStartDate"
                  no-title
                  locale="ja"
                  @input="showTargetStartDateSelector = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="4" cols="12">
              <v-menu
                v-model="showTargetEndDateSelector"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedTargetEndDateFormatted"
                    label="入庫日(終点)"
                    append-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedTargetEndDate"
                  no-title
                  locale="ja"
                  @input="showTargetEndDateSelector = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <small>車両情報を入力してください。</small>
              <v-divider class="mt-0 mb-3"></v-divider>
            </v-col>
            <v-col cols="12" lg="3" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="地域"
                rules="car_place"
              >
                <v-text-field
                  outlined
                  dense
                  label="地域 (例: 旭川)"
                  v-model="place"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="3" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="クラス"
                rules="car_class"
              >
                <v-text-field
                  outlined
                  dense
                  label="クラス (例: 500)"
                  v-model="classNumber"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="3" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="かな"
                rules="car_kana"
              >
                <v-text-field
                  outlined
                  dense
                  label="かな (例: あ)"
                  v-model="kana"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="3" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="4桁ナンバー"
                rules="car_number"
              >
                <v-text-field
                  outlined
                  dense
                  label="4桁ナンバー (例: 3, 88, 456)"
                  v-model="carNumber"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn outlined small color="success" :disabled="isDisabled" @click="getVehicleInformations">検索</v-btn>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-divider class="my-1"></v-divider>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-2">
            <small class="font-weight-bold">絞り込み条件</small>
          </v-col>
          <v-col lg="4" cols="6" class="pt-1 pb-0">
            <v-text-field v-model="search" append-icon="mdi-magnify" outlined dense clearable hide-details label="車両番号"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <div class="d-flex justify-space-between align-center py-1 ma-2">
                <div class="font-weight-bold text-subtitle-1">検索結果<span class="text-h6 pl-2">{{ itemCount }}<span class="pl-1 text-caption font-weight-bold">件</span></span></div>
                <div class="pa-2 d-flex justify-end">
                  <div class="d-flex align-center justify-end">
                    <div class="text-caption pl-4">表示件数</div>
                    <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
                  </div>
                </div>
              </div>
              <v-container v-if="isLoading" class="text-center my-10">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </v-container>
              <v-container v-else>
                <v-row>
                  <v-divider class="mx-0 my-1"></v-divider>
                </v-row>
                <v-row>
                  <v-col cols="12" class="px-0">
                    <v-data-table
                      :headers="headers"
                      :items="currentItems"
                      item-key="inoutNo"
                      :search="search"
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      @page-count="pageCount = $event"
                      :fixed-header="true"
                      persistent
                      hide-default-footer
                      class="elevation-1"
                      height="500px"
                    >
                    <template v-slot:item="{ item }">
                      <tr class="clickable-row" @click.stop="getShowVehicleInformations(item)">
                        <td><div class="py-2 vertical-center">{{ selectParkingName(item) }}</div></td>
                        <td><div class="py-2 vertical-center">{{ item.number }}</div></td>
                        <td><div class="py-2 vertical-center">{{ item.entrantTime }}</div></td>
                        <td><div class="py-2 vertical-center">{{ item.exitTime }}</div></td>
                        <td><div class="py-2 vertical-center white--text"><v-chip label small :color="selectStatusColor(item.status)"><span class="white--text">{{ item.status }}</span></v-chip></div></td>
                      </tr>
                    </template>
                    <template v-slot:footer>
                      <div class="pa-2">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                      </div>
                    </template>
                  </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-if="selectedItem" v-model="vehicleShowDialog" persistent max-width="1000px" scrollable>
      <v-card>
        <v-card-title>利用詳細</v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text>
          <v-container v-if="selectedItem && isShowLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-if="selectedItem && selectedVehicleInformation">
            <v-row>
              <v-col md="4" cols="6">
                <v-hover v-slot="{ hover }">
                  <v-img max-height="250" max-width="300" style="cursor: pointer;" class="pa-0 ma-0" :src="selectedItem.imagePath" @click="showImage()">
                  <v-overlay absolute :value="hover">
                    <v-icon :class="{ 'on-hover': hover}">mdi-magnify-plus-outline</v-icon>
                    </v-overlay>
                  </v-img>
                </v-hover>
              </v-col>
              <v-col md="8" cols="6">
                <v-container>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <div class="text-caption">車両番号</div>
                      <div><strong>{{ selectedItem.number }}</strong></div>
                      <div class="d-flex align-center">
                        <div class="px-1">
                          <div class="text-caption">属性</div>
                          <div v-if="checkVehicleCategory" class="white--text">
                            <v-chip v-if="selectedItem.white" small label color="primary">精算不要車両</v-chip>
                            <v-chip v-if="selectedItem.mont" small label color="primary">月極車両</v-chip>
                          </div>
                          <div v-else><v-chip small label>一般車両</v-chip></div>
                        </div>
                        <div class="px-1">
                          <div class="text-caption">利用状況</div>
                          <v-chip label small :color="selectStatusColor(selectedItem.status)"><span class="white--text">{{ selectedItem.status }}</span></v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.totalUsageTime }}</div>
                  <div class="text-caption">利用時間</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.barcodeCount }}<span class="text-caption">件</span></div>
                  <div class="text-caption">サービス券利用</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payCash }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（現金）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payCredit }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（クレジット）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payQr }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（QR決済）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payIcCard }}<span class="text-caption">円</span></div>
                  <div class="text-caption">支払金額（電子マネー）</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
              <v-col md="3" cols="6" max-width="100px">
                <div class="text-center">
                  <div class="font-weight-bold text-h6">{{ selectedVehicleInformation.payArrears }}<span class="text-caption">円</span>
                    <v-chip v-if="selectedVehicleInformation.payFinishedFlg === 1" x-small label class="mx-1 pa-2">支払済</v-chip>
                  </div>
                  <div class="text-caption">未払い金額</div>
                </div>
              </v-col>
              <v-divider vertical class="my-5"></v-divider>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="font-weight-bold">■ 利用履歴</div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="selectedVehicleInformation.inoutRecords"
                  :items-per-page="selectedVehicleInformation.inoutRecords.length"
                  dense
                  :headers="inoutHeaders"
                  mobile-breeakpoint="0"
                  no-data-text=""
                  hide-default-footer
                  fixed_header
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, i) in items" :key="i">
                        <td><div class="vertical-center">{{ item.nameJp ? item.nameJp : item.parkingName }}</div></td>
                        <td><div class="vertical-center">{{ item.entrantTime }}</div></td>
                        <td><div class="vertical-center">{{ item.exitTime }}</div></td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="font-weight-bold">■ 精算履歴</div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="selectedVehicleInformation.operationJournal"
                  :items-per-page="selectedVehicleInformation.operationJournal.length"
                  dense
                  :headers="operationJournalHeaders"
                  mobile-breeakpoint="0"
                  no-data-text=""
                  hide-default-footer
                  fixed_header
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, i) in items" :key="i">
                        <td><div class="vertical-center">{{ item.journalTime }}</div></td>
                        <td><div class="vertical-center">{{ item.NAME }}</div></td>
                        <td><div class="vertical-center">{{ item.SHORT_LABEL }}</div></td>
                        <td><div class="vertical-center">{{ item.PARAMATER }}</div></td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" :disabled="selectedItem && isShowLoading" @click.stop="close">閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import RestrictedFeatureAlert from '../../components/RestrictedFeatureAlert'

export default Vue.extend({
  name: 'vehicleInformations',
  components: {
    RestrictedFeatureAlert
  },
  data () {
    return {
      // 店舗情報
      selectedRequestClientStore: null,
      request_client_stores: [],
      // 日付
      showTargetStartDateSelector: false,
      selectedTargetStartDate: '',
      selectedTargetStartDateFormatted: '',
      showTargetEndDateSelector: false,
      selectedTargetEndDate: '',
      selectedTargetEndDateFormatted: '',
      place: null,
      classNumber: null,
      kana: null,
      carNumber: null,
      drawer: true,
      // フィルター
      selectedParkingId: 0,
      search: '',
      // テーブル
      filter: 0,
      parking: '全て',
      itemsPerPage: 100,
      page: 1,
      pageCount: 0,
      parkingNames: ['全て'],
      headers: [
        { text: '駐車場', value: 'parkingName', sortable: true, filterable: false },
        { text: '車両情報', value: 'number', sortable: true, filterable: true },
        { text: '入庫日時', value: 'entrantTime', sortable: true, filterable: false },
        { text: '出庫日時', value: 'exitTime', sortable: true, filterable: false },
        { text: '状態', value: 'status', sortable: true, filterable: false }
      ],
      inoutHeaders: [
        { text: '駐車エリア', value: 'nameJp' },
        { text: '入庫日時', value: 'entrantTime' },
        { text: '出庫日時', value: 'exitTime' }
      ],
      operationJournalHeaders: [
        { text: '精算日時', value: 'jourbalTime' },
        { text: '精算機種', value: 'NAME' },
        { text: '状態', value: 'SHORT_LABEL' },
        { text: '詳細', value: 'PARAMATER' }
      ],
      filterItems: [ { text: '全て', value: 0 }, { text: '入庫中', value: 1 }, { text: '出庫済み', value: 2 }, { text: '移動', value: 3 }, { text: '未精算', value: 4 }],
      vehicle_informations: [],
      selectedVehicleInformation: null,
      selectedItem: null,
      vehicleShowDialog: false,
      isShowLoading: false,
      isLoading: false,
    }
  },
  computed: {
    isDisabled () {
      return !this.selectedRequestClientStore || this.isLoading
    },
    selectStatusColor () {
      return (value) => {
        if (value === "出庫済み") {
          return "success"
        } else if (value === "未精算") {
          return "error"
        } else if (value === "入庫中") {
          return "amber"
        }
      }
    },
    currentItems () {
      let filterItems = []
      if (this.filter === 0) {
        filterItems =  this.vehicle_informations
      } else if (this.filter === 1) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '入庫中' )
      } else if (this.filter === 2) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '出庫済み' )
      } else if (this.filter === 3) {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '移動' )
      } else {
        filterItems = this.vehicle_informations.filter((vehicle) => vehicle.status === '未精算' )
      }
      if (this.parking === "全て") {
        return filterItems
      } else {
        return filterItems.filter((item) => item.parkingName === this.parking)
      }
    },
    itemCount () {
      return this.vehicle_informations ? this.currentItems.length : 0
    },
    checkVehicleCategory () {
      return this.selectedItem && (this.selectedItem.white || this.selectedItem.mont)
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.vehicle_informations.length }] }
    },
    selectParkingName () {
      return (elm) => {
        return this.checkRakeTown ? elm.rakeParkingName : elm.parkingName
      }
    },
    checkRakeTown () {
      return this.selectedRequestClientStore?.address.includes("smp2-483")
    }
  },
  watch: {
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    }
  },
  methods: {
    async getVehicleInformations () {
      this.page = 1
      this.filter = 0
      this.parking = '全て'
      this.vehicle_informations = []
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isAeonMakuhariUser && !this.$store.getters.isAeonYonoAdmin && !this.$store.getters.isRakeTownAdmin) {
        this.$router.push({path: "/"})
        return
      }
      this.isLoading = true
      const numberInfo = {
        place: this.place,
        class_number: this.classNumber,
        kana: this.kana,
        car_number: this.carNumber ? ('****' + this.carNumber).slice(-4) : null
      }
      const params = {
        store_id: this.selectedRequestClientStore.id,
        start_date: this.selectedTargetStartDateFormatted,
        end_date: this.selectedTargetEndDateFormatted,
        number_info: numberInfo
      }
      const url = process.env.VUE_APP_BASE_URL + "makuharishintoshin_vehicle_informations"
      try {
        const response = await this.axios.get(url, { params: params, headers: { 'Content-Type': 'application/json' } })
        this.vehicle_informations = response.data.vehicle_informations
        this.parkingNames = [...this.parkingNames, ...response.data.parking_names]
      } catch (err) {
        console.log(err)
        if (this.$route.path.match('/login')) {
          return
        } else if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `入出庫データの取得に失敗しました`,
            type: "is-danger",
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async getShowVehicleInformations (item) {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isAeonMakuhariUser && !this.$store.getters.isAeonYonoAdmin && !this.$store.getters.isRakeTownAdmin) {
        this.$router.push({path: "/"})
        return
      }
      this.selectedVehicleInformation = null
      this.selectedItem = item
      const params = `?store_id=${this.selectedRequestClientStore.id}&entrant_no=${item.sourceEntrantNo}&parking_id=${item.sourceParkingId}&entrant_dte=${item.sourceEntrantDte}`
      const url = process.env.VUE_APP_BASE_URL + "makuharishintoshin_vehicle_informations/" + item.inoutNo + params
      this.vehicleShowDialog = true
      this.isShowLoading = true
      try {
        const response = await this.axios.get(url)
        this.selectedVehicleInformation = response.data
      } catch (err) {
        if (this.$route.path.match('/login')) {
          return
        } else if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `入出庫データの取得に失敗しました`,
            type: "is-danger",
          })
        }
      } finally {
        this.isShowLoading = false
      }
    },
    close () {
      this.selectedVehicleInformation = null
      this.selectedItem = null
      this.vehicleShowDialog = false
    }
  },
  mounted () {
    this.selectedTargetStartDateFormatted = this.selectedTargetStartDateFormatted || moment().format('yyyy/MM/DD')
    this.selectedTargetEndDateFormatted = this.selectedTargetEndDateFormatted || moment().format('yyyy/MM/DD')
    this.getRequestClientStores()
  }
})
</script>
<style scoped>
.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
}
.v-img .on-hover { transition: opacity .4s ease-in-out; cursor: pointer; }
.on-hover { opacity: 1; }
.clickable-row { cursor: pointer; }
</style>
